import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useUID} from 'react-uid';

import {colorVariants} from '../utils';
import * as css from './Select.module.css';

export const Select = ({name, value, label, options = [], onChange, variant, className, ...otherProps}) => {
  const id = useUID();
  const onSelectChange = e => {
    let {value} = e.target;
    value = value === 'false' ? false : value;
    value = value === 'true' ? true : value;
    onChange && onChange(name, e.target.value, e);
  };

  return (
    <div className={classnames(css.root, className, css[variant])}>
      <label className={classnames(css.label)} htmlFor={id}>
        {label || name}
      </label>

      <select
        value={onChange ? value : undefined}
        defaultValue={onChange ? undefined : value}
        className={css.select}
        id={id}
        onChange={onSelectChange}
      >
        {options.map((option, index) => {
          const optValue = option.value || option;
          const optLabel = option.label || option;
          return (
            <option key={index} value={optValue}>
              {optLabel}
            </option>
          );
        })}
      </select>
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.bool,
  variant: PropTypes.oneOf(colorVariants),
  className: PropTypes.string,
};

export default Select;
